const FanZoneBubamaraTV = () => {
  return (
    <div className="text-center">
      <p className="text-gray-900 text-lg">Bubamara TV</p>
      <div className="mt-4">
        <iframe
          width="full"
          height="315"
          src="https://www.youtube.com/embed/DdDkEtCivuk?si=mNUWpVvKhoB6H7Ef"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="mt-4">
        <iframe
          width="full"
          height="315"
          src="https://www.youtube.com/embed/5XxZmPnyvPo?si=1NL9cUiiVMtLbxI1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="mt-4">
        <iframe
          width="full"
          height="315"
          src="https://www.youtube.com/embed/bSHn7to9sp8?si=evVzdXQHQO_o_umO"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="mt-4">
        <iframe
          width="full"
          height="315"
          src="https://www.youtube.com/embed/qwbbcxLtgz0?si=7DjZ5ftmPBfbLB_I"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="mt-4">
        <iframe
          width="full"
          height="315"
          src="https://www.youtube.com/embed/cx7_F7k7p-0?si=AjDB9wQdhb_JehzK"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default FanZoneBubamaraTV;
