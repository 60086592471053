const FanZoneSquad = () => {
  return (
    <div className="text-center">
      <p className="text-gray-900 text-lg">Tim 2024/2025</p>
      <p className="mt-2 text-gray-600">U izradi...</p>
    </div>
  );
};

export default FanZoneSquad;
