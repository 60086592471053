import React, { useState, useEffect } from "react";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { firestore } from "../../firebase"; // Make sure this path is correct
import {
  FaCalendarAlt,
  FaClock,
  FaMapMarkerAlt,
  FaArrowLeft,
} from "react-icons/fa"; // Import icons from Font Awesome

const FanZoneEventDetails = ({ event, onBack, userData, qrCodeData }) => {
  const [userAttendance, setUserAttendance] = useState(null);

  useEffect(() => {
    // Check if the user is already marked as attending or not attending
    if (event.coming?.includes(qrCodeData.qrID)) {
      setUserAttendance("coming");
    } else if (event.notComing?.includes(qrCodeData.qrID)) {
      setUserAttendance("notComing");
    }
  }, [event, qrCodeData.qrID]);

  const handleAttendance = async (status) => {
    const eventDocRef = doc(firestore, "events", event.id);

    try {
      if (status === "coming") {
        await updateDoc(eventDocRef, {
          coming: arrayUnion(qrCodeData.qrID),
          notComing: arrayRemove(qrCodeData.qrID), // Remove from "notComing"
        });
        setUserAttendance("coming");
      } else {
        await updateDoc(eventDocRef, {
          notComing: arrayUnion(qrCodeData.qrID),
          coming: arrayRemove(qrCodeData.qrID), // Remove from "coming"
        });
        setUserAttendance("notComing");
      }
    } catch (error) {
      console.error("Error updating attendance: ", error);
    }
  };

  // Check if user can see the buttons (season card or Bubamarino Krilo)
  const canShowButtons = qrCodeData?.isSeasonCard || userData?.bubamaraWing;

  return (
    <div className="mt-4">
      {/* Back button with back icon */}
      <button
        onClick={onBack}
        className="bg-red-700 text-white py-2 px-4 rounded-lg flex items-center mb-4 hover:bg-red-600 transition duration-300"
      >
        <FaArrowLeft className="mr-2" />
        Nazad
      </button>

      <div className="bg-white p-4 rounded-lg shadow-md">
        {/* Event highlight image */}
        <img
          src={event.imageUrl}
          alt={event.title}
          className="w-full h-64 object-cover rounded-lg mb-4"
        />

        {/* Event title */}
        <h2 className="text-2xl font-bold text-gray-900 mb-4">{event.title}</h2>

        {/* Event date with calendar icon */}
        <div className="flex items-center text-gray-600 mb-2">
          <FaCalendarAlt className="mr-2" />
          <span>{event.date}</span>
        </div>

        {/* Event time with clock icon */}
        <div className="flex items-center text-gray-600 mb-2">
          <FaClock className="mr-2" />
          <span>{event.time}</span>
        </div>

        {/* Event venue with location pin icon */}
        <div className="flex items-center text-gray-600 mb-4">
          <FaMapMarkerAlt className="mr-2" />
          <span>{event.venue}</span>
        </div>

        {/* Event description */}
        <p className="text-gray-900">{event.description}</p>

        {/* Attendance Buttons */}
        {canShowButtons && (
          <div className="mt-4">
            {userAttendance === "coming" ? (
              <div className="flex justify-between items-center">
                <p className="text-green-600 font-bold uppercase sm:text-sm">
                  Dolazim
                </p>
                <button
                  onClick={() => handleAttendance("notComing")}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg"
                >
                  Ne dolazim
                </button>
              </div>
            ) : userAttendance === "notComing" ? (
              <div className="flex justify-between items-center">
                <p className="text-red-600 font-bold uppercase sm:text-sm">
                  Ne dolazim
                </p>
                <button
                  onClick={() => handleAttendance("coming")}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg"
                >
                  Dolazim
                </button>
              </div>
            ) : (
              <div className="flex justify-between items-center">
                <button
                  onClick={() => handleAttendance("coming")}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg"
                >
                  Dolazim
                </button>
                <button
                  onClick={() => handleAttendance("notComing")}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg ml-2"
                >
                  Ne dolazim
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FanZoneEventDetails;
